import React from 'react';

import { graphql } from 'gatsby';

import MediaPageHeaderDesign from '../../../../assets/images/mediapage-design-header.inline.svg';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Seo from '../../../../components/Seo/Seo';
import useMedias from '../../../../hooks/useMedias';
import Layout from '../../../../Layout';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getBreadCrumb } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const CommiteMemberPage = ({ data }) => {
  const { processUrl, getImage } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const biographyData = data?.biographyData.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, biographyData.path?.alias);
  const visuel = getImage(
    imagesArray,
    biographyData?.relationships?.field_image?.drupal_internal__mid
  );
  const metaTags = biographyData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={biographyData?.field_metatag?.title ? biographyData?.field_metatag?.title : metaTitle}
        description={biographyData?.field_metatag?.description ? biographyData?.field_metatag?.description : metaDesc}
        shareImage={processUrl(getImage(imagesArray, biographyData.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_medium)}
      />
      <div className={classNames('page_template', 'comite_member_page')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              grandparentPage: {
                title: breadCrumb?.grandParent?.name,
                url: breadCrumb?.grandParent?.link,
              },
              gparent: {
                title: breadCrumb?.gGrandParent?.name,
                url: breadCrumb?.gGrandParent?.link,
              },
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: breadCrumb?.current?.name,
                url: breadCrumb?.current?.link,
              },
              locale: biographyData.langcode,
            }}
          />
        </div>
        <section className="section_content section_member_direction">
          <div className="wrapper_page">
            <TitleSection
              type="line"
              title={biographyData?.title}
              description={biographyData?.field_poste}
              normalText={
                biographyData?.field_date_de_naissance !== null &&
                biographyData?.field_date_de_naissance !== undefined &&
                biographyData?.field_date_de_naissance
              }
              forceTagH1={true}
            />
          </div>
          <div className="wrapper_page_xs">
            <div
              className={classNames(
                'col_2_special',
                'cross_wrapper_extreme_left'
              )}
            >
              <div className="visuel">
                {visuel && (
                  <div className="visuel_square">
                    <div className="image-wrapper">
                      <img
                        src={processUrl(visuel?.image_style_uri?.gatsby_profile_large)}
                        alt={biographyData?.title}
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={classNames('text')}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: biographyData.body?.processed,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query biographyTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    biographyData: allNodeBiographie(
      filter: {
        langcode: { eq: $locale }
        path: { alias: { eq: $slug } }
        status: { eq: true }
      }
    ) {
      edges {
        node {
          title
          langcode
          body {
            processed
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_date_de_naissance
          field_poste
          relationships {
            field_image {
              drupal_internal__mid
            }
          }
          path {
            alias
            langcode
          }
          drupal_id
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default CommiteMemberPage;
